<template>
  <div>
    <!-- Filters -->
    <!--    <Filters-->
    <!--      v-model="filter"-->
    <!--      :loading.sync="loading"-->
    <!--      :month.sync="monthFilter"-->
    <!--      :year.sync="yearFilter"-->
    <!--      @search="handleSearch"-->
    <!--      @clear-filter="handleClearFilter"-->
    <!--    />-->

    <b-card no-body>
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          Khách hàng ({{ totalCustomers }})
        </b-badge>
      </b-card-header>
      <div class="d-flex gap-1 px-2 pb-1">
        <template
          v-for="(item, itemIdx) in customersReport"
        >
          <b-badge
            v-if="item.status !== 'new'"
            :key="'customer-report-item-' + itemIdx"
            class="customer-badge text-black py-50 px-1 cursor-pointer large"
            :class="{
              ['bg-' + item.status]: true,
            }"
            @click="setTableHeaderFilter('status', item.status)"
          >
            {{ (STATUS_TYPE_CUSTOMER.find(_item => _item.value === item.status) || {}).text || '' }} ({{ item.count }})
          </b-badge>
        </template>
      </div>
    </b-card>

    <b-overlay
      id="overlay-bg-customer-list"
      class="custom-overlay-icon-top"
      :show="loading"
      variant="white"
      opacity=".5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0 pb-0"
      >
        <b-card-header
          class="py-1 px-2 d-flex justify-content-between"
        >
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50"
          >
            Danh sách khách hàng ({{ totalCustomers }})
          </b-badge>
          <table-column-toggle
            id="refCustomerListTable"
            :table-columns="tableColumns"
            :show-btn-filter="true"
            @update-columns-show="updateTableColumns"
            @trigger-filter="handleSearch"
            @reset-filter="resetHeaderFilter"
          />
        </b-card-header>

        <b-table
          ref="refCustomerListTable"
          class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
          responsive
          bordered
          hover
          primary-key="id"
          show-empty
          small
          no-local-sorting
          empty-text="Không có kết quả nào"
          :items="customers"
          :fields="tableColumns"
          @row-clicked="gotoCustomerDetail"
          @sort-changed="handleSortTable"
        >

          <template #cell(title)="data">
            <div class="max-2line">
              {{ data.item.title }}
            </div>
          </template>

          <template #cell(note)="data">
            <div class="max-2line">
              {{ data.item.note }}
            </div>
          </template>

          <template #head(created_at)="data">
            <div class="d-flex flex-column gap-50">
              <div>{{ data.label }}</div>
              <flat-pickr
                id="move_in_at"
                v-model="tableHeaderFilter[data.column]"
                class="form-control flat-pickr-small"
                :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

          <template #head(host_name)="data">
            <div class="d-flex flex-column gap-50">
              <div>{{ data.label }}</div>
              <b-form-input
                v-model="tableHeaderFilter[data.column]"
                size="sm"
                :placeholder="data.label"
              />
            </div>
          </template>
          <template #cell(host_name)="data">
            <div class="d-flex flex-column gap-50 text-primary">
              {{ data.item.host_name }}
            </div>
          </template>

          <template #cell(source)="data">
            <div class="d-flex flex-column gap-50">
              {{ (CUSTOMER_SOURCE.find(item => item.value === data.item.source) || {}).text || '' }}
            </div>
          </template>

          <template #head(host_phone)="data">
            <div class="d-flex flex-column gap-50">
              <div>{{ data.label }}</div>
              <b-form-input
                v-model="tableHeaderFilter[data.column]"
                size="sm"
                :placeholder="data.label"
              />
            </div>
          </template>
          <template #cell(host_phone)="data">
            <div class="d-flex flex-column gap-50 text-primary">
              {{ data.item.host_phone1 }}
              <template v-if="data.item.host_phone2">
                <br>
                {{ data.item.host_phone2 }}
              </template>
            </div>
          </template>

          <template #head(career)="data">
            <div class="d-flex flex-column gap-50">
              <div>{{ data.label }}</div>
              <v-select
                v-model="tableHeaderFilter[data.column]"
                :label="'name'"
                class="bg-white"
                :input-id="'name'"
                :reduce="option => option.name"
                size="sm"
                :options="careers"
                :clearable="true"
              />
            </div>
          </template>

          <template #head(price)="data">
            <div class="d-flex flex-column gap-50">
              <div>{{ data.label }}</div>
              <div class="custom-filter-inline">
                <b-form-input
                  v-model="tableHeaderFilter.price"
                  :placeholder="data.label"
                />
                <v-select
                  id="filter-form-price-from-currency"
                  v-model="tableHeaderFilter.currency"
                  class="v-select-no-wrap"
                  :reduce="(option) => option.value"
                  :label="'text'"
                  :options="currencyList"
                  :clearable="false"
                />
              </div>
            </div>
          </template>

          <!--          <template #head(price)="data">-->
          <!--            <div class="d-flex flex-column">-->
          <!--              <div class="custom-filter-inline">-->
          <!--                <span>{{ data.label }}</span>-->
          <!--                <v-select-->
          <!--                  id="filter-form-price-from-currency"-->
          <!--                  v-model="tableHeaderFilter.currency"-->
          <!--                  class="v-select-no-wrap"-->
          <!--                  :reduce="(option) => option.value"-->
          <!--                  :label="'text'"-->
          <!--                  :options="currencyList"-->
          <!--                  :clearable="false"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="custom-filter-inline">-->
          <!--                <b-form-input-->
          <!--                  v-model="tableHeaderFilter.min_price"-->
          <!--                  placeholder="min"-->
          <!--                />-->
          <!--                <span>≤</span>-->
          <!--                <b-form-input-->
          <!--                  v-model="tableHeaderFilter.max_price"-->
          <!--                  placeholder="max"-->
          <!--                />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </template>-->

          <template #cell(price)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{ formatNumber(data.item.min_price) }}-{{
                  formatNumber(data.item.max_price)
                }} <small>{{
                  (CURRENCY.find(item => item.value === data.item.currency) || {text: 'usd'}).text.toUpperCase()
                }}</small>
              </span>
            </div>
          </template>

          <template #head(status)="data">
            <div class="d-flex flex-column gap-50">
              <div>{{ data.label }}</div>
              <v-select
                v-model="tableHeaderFilter[data.column]"
                :label="'text'"
                class="bg-white"
                :input-id="'value'"
                :reduce="option => option.value"
                size="sm"
                :options="STATUS_TYPE_CUSTOMER"
                :clearable="true"
              />
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge
              class="customer-badge"
              :class="{
                ['bg-' + data.item.status]: true,
              }"
            >
              {{ (STATUS_TYPE_CUSTOMER.find(item => item.value === data.item.status) || {}).text || '' }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="customer-actions-wrapper">
              <b-button
                v-if="ability().can('edit', subject('CustomerCorner', data.item))"
                variant="outline-warning"
                size="sm"
                class="p-50"
                @click="openModalFastEdit(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                variant="outline-primary"
                size="sm"
                class="p-50"
                @click="gotoCustomerDetail(data.item)"
              >
                <feather-icon
                  icon="SendIcon"
                />
              </b-button>
              <b-button
                v-if="ability().can('delete', subject('CustomerCorner', data.item))"
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="openModalConfirmDeleteCustomer(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </div>
          </template>

        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mỗi trang</label>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalCustomers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

    </b-overlay>

    <b-modal
      id="modal-fast-edit-customer"
      ref="modal-fast-edit-customer"
      cancel-title="Hủy"
      ok-title="Cập nhật"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      :title="'Cập nhật nhanh ' + currentCustomerUpdate.host_name"
      @ok="handleFastUpdateCustomer"
    >
      <b-card-text>
        <!--              Tình trạng-->
        <dynamic-form
          :id="'customer-form-status'"
          :value="currentCustomerUpdate.status"
          :type="'select'"
          :label="'Tình trạng'"
          :class="'form-group-hor'"
          :options="STATUS_TYPE_CUSTOMER"
          @update-value="val => currentCustomerUpdate.status = val"
        />

        <div class="form-group-hor">
          <label>Ngày hẹn khách</label>
          <flat-pickr
            ref="customer-form-meeting_date"
            v-model="currentCustomerUpdate.meeting_date"
            class="form-control"
            :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
          />
        </div>

        <!--            Số lần đã gặp-->
        <dynamic-form
          :id="'customer-form-meeting_count'"
          :value="currentCustomerUpdate.meeting_count"
          :type="'text'"
          :label="'Số lần đã gặp'"
          placeholder="Số lần đã gặp"
          :class="'form-group-hor'"
          @update-value="val => currentCustomerUpdate.meeting_count = val"
        />

        <!--            Số lần đã chốt-->
        <dynamic-form
          :id="'customer-form-confirm_deal_count'"
          :value="currentCustomerUpdate.confirm_deal_count"
          :type="'text'"
          :label="'Số lần đã chốt'"
          placeholder="Số lần đã chốt"
          :class="'form-group-hor'"
          @update-value="val => currentCustomerUpdate.confirm_deal_count = val"
        />

        <dynamic-form
          :id="'customer-form-note'"
          :value="currentCustomerUpdate.note"
          :type="'textarea'"
          :label="'Ghi chú'"
          placeholder="Ghi chú"
          :class="'form-group-hor'"
          @update-value="val => currentCustomerUpdate.note = val"
        />

      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-confirm-delete-customer"
      ref="modal-confirm-delete-customer"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="danger"
      :title="'Xóa' + (customerMenuType ? ' Sản Phẩm Thuê #' : ' Sản Phẩm Bán #') + currentCustomer.id"
      @ok="handleDeleteCustomer"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span>
        {{ (customerMenuType ? ' Sản Phẩm Thuê' : ' Sản Phẩm Bán') + ' mã số: ' }}
        <span class="text-primary font-weight-bolder">{{ (currentCustomer.id) }}</span> ?
        <br>
        <br>
        <small class="text-primary">{{ currentCustomer.title }}</small>
        <br>
        <br>
        <b-button
          variant="primary"
          size="sm"
          @click="gotoCustomerDetail(currentCustomer)"
        >
          Xem chi tiết
        </b-button>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-export-excel"
      ref="modal-export-excel"
      cancel-title="Hủy"
      ok-title="Xuất excel"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      title="Chọn thời gian xuất Excel"
      @ok="handleExportExcel"
    >
      <b-card-text>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Thời gian">
              <b-form-radio-group
                v-model="exportExcelTime"
                stacked
              >
                <b-form-radio value="week">
                  Theo tuần (7 ngày qua)
                </b-form-radio>
                <b-form-radio value="month">
                  Theo tháng (30 ngày qua)
                </b-form-radio>
                <b-form-radio value="">
                  Tất cả thời gian
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Close Deal">
              <b-form-radio-group
                v-model="exportExcelCloseDeal"
                stacked
              >
                <b-form-radio value="">
                  Tất cả
                </b-form-radio>
                <b-form-radio value="active">
                  Close Deal
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Từ</label>
              <flat-pickr
                id="move_in_at"
                v-model="timeFrom"
                class="form-control"
                :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Đến</label>
              <flat-pickr
                id="move_in_at"
                v-model="timeTo"
                class="form-control"
                :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCol, BMediaAside, BPagination, BRow, BTable, VBTooltip, BBadge, BButton, BOverlay,
  BCardText, BFormGroup, BFormRadioGroup, BFormRadio, BCardHeader, BFormInput, BCardBody,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import customerStoreModule from '@/views/customer-hotel/customerStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import useCustomerList from '@/views/customer-hotel/list/useCustomerList'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import flatPickr from 'vue-flatpickr-component'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import axiosIns from '@/libs/axios'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import Filters from './Filters.vue'

export default {
  components: {
    DynamicForm,
    BCardBody,
    BFormInput,
    BCardHeader,
    Filters,
    BAvatar,
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BMediaAside,
    BBadge,
    BButton,
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    flatPickr,
    Actions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
    }
  },
  computed: {
    Vietnamese() {
      return Vietnamese
    },
    currencyList() {
      return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
    },
    actionsButton() {
      const validActions = []
      if (ability()
        .can('export-excel')) {
        validActions.push(this.ACTIONS_BUTTON.EXPORT_EXCEL)
      }
      return validActions
    },
  },
  created() {
    const { filter } = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
    this.monthFilter = this.filter.monthFilter || ''
    this.yearFilter = this.filter.yearFilter || ''
    store.dispatch('app/fetchUsers')
    this.filter.province_id = '1' // HCM
    this.fetchCustomers()
      .finally(() => {
        this.loading = false
      })
    // waiting for watching done
    setTimeout(() => {
      this.created = true
    }, 500)
  },
  methods: {
    handleFastUpdateCustomer() {
      this.$call(this.updateCustomer(this.currentCustomerUpdate), true)
        .then(({ id }) => {
          const found = this.customers.find(c => c.id === id)
          if (found) {
            Object.assign(found, this.currentCustomerUpdate)
          }
        })
    },
    openModalFastEdit(customer) {
      Object.assign(this.currentCustomerUpdate, customer)
      this.$refs['modal-fast-edit-customer'].show()
    },
    fetchCustomerHistory(row) {
      this.currentCustomerClicked = row
      axiosIns.get(`/api.client/history/${row.id}`).then(res => {
        this.currentCustomerHistory = res.data && res.data.data
      }).catch(e => {
        this.$toastr(`Có lỗi xảy ra!${e}`, 'danger')
      })
    },
    resetHeaderFilter(initFilter) {
      this.clearHeaderFilter(initFilter)
    },
    setTableHeaderFilter(filterName, filterValue) {
      this.tableHeaderFilter[filterName] = filterValue
    },
    gotoCustomerEdit(customer) {
      this.$router.push({
        name: `customer-${this.customerMenuType}-edit`,
        params: { id: customer.id },
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'excel') {
        this.$refs['modal-export-excel'].show()
      }
    },
    handleExportExcel() {
      this.$toastr('Đang xuất file, vui lòng chờ giây lát!')
      this.$call(this.exportExcelCustomer()
        .then(res => {
          this.downloadUrl(res.data, 'excel')
        }))
    },
    openModalConfirmDeleteCustomer(customer) {
      this.currentCustomer = customer
      this.$refs['modal-confirm-delete-customer'].show()
    },
    handleDeleteCustomer() {
      this.$call(this.deleteCustomer(this.currentCustomer.id), true)
        .then(() => {
          const foundIdx = this.customers.findIndex(item => item.id === this.currentCustomer.id)
          if (foundIdx !== -1) {
            this.customers.splice(foundIdx, 1)
          }
        })
    },
    gotoCustomerDetail({ id }) {
      const route = this.$router.resolve({
        name: `customer-${this.customerMenuType}-detail`,
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchCustomers()
        .finally(() => {
          this.loading = false
        })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoCustomerDetail(row)
    },
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    return {
      ...useCustomerList(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.customer-actions-wrapper {
  display: flex;
  gap: 1rem;

  > * {
    padding: .25rem !important;
  }

  .customer-badge {
    position: absolute;
    right: 0;
    font-size: .7rem !important;

    &.hot {
      top: .25rem;
    }

    &.corner {
      bottom: .25rem;
    }
  }
}
</style>
